// This represents a second signup process that involves:
// 1. A single subscription price
// 2. Doesn't allow the user to sign in until a certain date

import Vue from "vue/dist/vue.esm";

import vSelect from "vue-select";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueScrollTo from "vue-scrollto";
import axios from "axios";

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScrollTo);

Vue.component("v-select", vSelect);

document.addEventListener("turbolinks:load", () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";

  const STATUS_REGISTERING = "registering";
  const STATUS_SELECTING_PLAN = "selectingPlan";
  const STATUS_COLLECTING_PAYMENT_INFO = "collectingPaymentInfo";
  const STATUS_PAYMENT_COMPLETE = "paymentComplete";
  const STATUS_PORTIONS_PROFILE_COMPLETE = "portionsProfileComplete";
  const STATUS_EDITING = "editing";
  const STATUS_UPGRADING = "upgrading";

  // for premium coaching sign up
  const STATUS_SINGLE_PAYMENT_INVOICE_COMPLETE = "singlePaymentInvoiceComplete";
  const STATUS_SUBSCRIPTION_SCHEDULE_COMPLETE = "subscriptionScheduleComplete";

  const PLAN_BASIC = "basic";
  const PLAN_PREMIUM = "premium";

  const QuestionnaireForm = Vue.component("questionnaire-coaching-form", {
    template: "#questionnaire-coaching-form-template",
    props: [
      "ageGroupOptions",
      "ethnicityOptions",
      "formType",
      "planOptions",
      "questionnaire_answer",
      "timeZoneOptions",
      "user",
    ],
    mounted: function () {
      this.initializeFormData();
      this.loadUserData();
    },
    updated: function () {
      if (
        this.status === STATUS_COLLECTING_PAYMENT_INFO &&
        !this.stripeLoaded
      ) {
        this.includeStripe(
          "js.stripe.com/v3/",
          function () {
            this.configureStripe();
          }.bind(this)
        );
      }
    },
    data: function () {
      return {
        ageGroup: {
          label: "",
          id: "",
        },
        birthdayMonth: "",
        birthdayDay: "",
        bodyType: "",
        difficultyGainingWeight: "",
        dayOptions: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
        energyFluctuations: "",
        errorMessage: "",
        errors: {},
        ethnicity: "",
        femaleBodyTypeOptions: [
          "Body Type 1",
          "Body Type 2",
          "Body Type 3",
          "Body Type 4",
        ],
        earlyOnsetPuberty: "",
        email: "",
        energyLevel: "",
        firstName: "",
        faceShape: "",
        foodCraving: "",
        femaleFaceShapeOptions: [
          "Square shaped, possibly with some light masculine qualities",
          "Delicate features with feminine qualities, slightly small in proportion to body",
          'Round, with the look of "baby fat" that makes you look younger than you are, slightly large in proportion to your body',
          "Long and slender (oval)",
        ],
        femaleFoodCravingOptions: [
          "Salty and fatty, like meat, hard cheeses, and butter.",
          "Spicy (like Thai or Mexican), fried, and rich, creamy foods.",
          "Dairy foods, such as yogurt, cheese, and cottage cheese.",
          "Sweets like cake, cookies, and chocolate, plus caffeinated drinks.",
        ],
        gender: "",
        genderOptions: ["M", "F"],
        group: "",
        hasError: false,
        idealWeight: "",
        lastName: "",
        latestInvoiceId: "",
        latestInvoicePaymentIntentStatus: "",
        maleBodyTypeOptions: ["Body Type 1", "Body Type 3", "Body Type 4"],
        maleFaceShapeOptions: [
          "Square shaped",
          'Round, with the look of "baby fat" that makes you look younger than you are, slightly large in proportion to your body',
          "Long and slender (oval)",
        ],
        maleFoodCravingOptions: [
          "Salty and fatty, like meat, hard cheeses, and butter.",
          "Dairy foods, such as yogurt, cheese, and cottage cheese.",
          "Sweets like cake, cookies, and chocolate, plus caffeinated drinks.",
        ],
        monthOptions: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        morningPerson: "",
        originalDayOptions: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
        password: "",
        passwordConfirmation: "",
        paymentOptions: ["1 payment of $2,899*", "4 payments of $797*"],
        physicalActivity: "",
        physicalActivityOptions: ["Sedentary/Light", "Moderate", "Heavy"],
        plan: "",
        postMenopausalCombo: "",
        promoCode: "",
        selectedPaymentOption: "",
        sluggishMetabolism: "",
        status: STATUS_REGISTERING,
        stripeAPIToken: "",
        stripeCustomerId: "",
        stripeLoaded: false,
        stripeObject: "",
        timeZone: "",
        userId: "",
        userSignedIn: false,
        weight: "",
        yesOrNoOptions: [
          { label: "Yes", code: true },
          { label: "No", code: false },
        ],
      };
    },
    computed: {
      appliedText: function () {
        if (this.promoCodeApplied) {
          return " Promo Applied";
        }
      },
      basicPlan: function () {
        return this.plan.id === PLAN_BASIC;
      },
      displayFemaleQuestionnaire: function () {
        return this.isFemale;
      },
      displayMaleQuestionnaire: function () {
        return this.isMale;
      },
      displayPaymentCollectionForm: function () {
        return this.status === STATUS_COLLECTING_PAYMENT_INFO;
      },
      displayPlanSelectionForm: function () {
        return (
          this.status === STATUS_SELECTING_PLAN ||
          this.status === STATUS_UPGRADING
        );
      },
      displayPortionsProfileQuestions: function () {
        return (
          this.status === STATUS_PAYMENT_COMPLETE ||
          this.status === STATUS_EDITING
        );
      },
      displayRegistrationQuestions: function () {
        return (
          this.status === STATUS_REGISTERING || this.status === STATUS_EDITING
        );
      },
      displayUpgradingPlanSelectionHeader: function () {
        return this.status === STATUS_UPGRADING;
      },
      editForm: function () {
        return this.formType === "edit";
      },
      femaleQuestionnaireComplete: function () {
        return (
          this.generalQuestionnaireComplete &&
          this.earlyOnsetPuberty &&
          this.postMenopausalCombo
        );
      },
      fullBirthday: function () {
        return this.birthdayMonth + " " + this.birthdayDay;
      },
      fullName: function () {
        return this.firstName + " " + this.lastName;
      },
      generalQuestionnaireComplete: function () {
        let complete =
          this.ageGroup &&
          this.birthdayDay &&
          this.birthdayMonth &&
          this.bodyType &&
          this.difficultyGainingWeight &&
          this.email &&
          this.energyLevel &&
          this.ethnicity &&
          this.firstName &&
          this.faceShape &&
          this.foodCraving &&
          this.gender &&
          this.idealWeight &&
          this.lastName &&
          this.morningPerson &&
          this.physicalActivity &&
          this.sluggishMetabolism &&
          this.timeZone &&
          this.weight;
        if (this.newForm) {
          return complete && this.password && this.passwordConfirmation;
        } else if (this.editForm) {
          return complete;
        }
      },
      isFemale: function () {
        return this.gender === "F";
      },
      isMale: function () {
        return this.gender === "M";
      },
      maleQuestionnaireComplete: function () {
        return this.generalQuestionnaireComplete && this.energyFluctuations;
      },
      newForm: function () {
        return this.formType === "new";
      },
      paymentPlanSelected: function () {
        return this.selectedPaymentOption === "4 payments of $797*";
      },
      planCost: function () {
        if (this.basicPlan) {
          return 199;
        } else if (this.premiumPlan) {
          return 299;
        } else {
          return 0;
        }
      },
      planSelectionFormComplete: function () {
        return this.plan;
      },
      planSummary: function () {
        if (this.basicPlan) {
          return "Basic";
        } else if (this.premiumPlan) {
          return "Premium";
        } else {
          return "No Plan Selected";
        }
      },
      premiumMonthly: function () {
        return this.premiumPlan && this.monthly;
      },
      premiumPlan: function () {
        return this.plan.id === PLAN_PREMIUM;
      },
      promoCodeApplied: function () {
        return (
          this.promoCode === "BETATEST" &&
          this.plan.id === "premium" &&
          this.paymentSchedule.id === "Every 3 Months"
        );
      },
      questionnaireComplete: function () {
        if (this.isMale) {
          return this.maleQuestionnaireComplete;
        } else {
          return this.femaleQuestionnaireComplete;
        }
      },
      registrationFormComplete: function () {
        return (
          this.birthdayDay &&
          this.birthdayMonth &&
          this.firstName &&
          this.email &&
          this.ethnicity &&
          this.lastName &&
          this.password &&
          this.passwordConfirmation &&
          this.timeZone
        );
      },
      selectedGroupIsFull: function () {
        return false;
      },
      submitButtonText: function () {
        return this.newForm ? "Create My Portion's Profile" : "Update Profile";
      },
      totalBilled: function () {
        if (this.promoCodeApplied) {
          return 0.0;
        } else {
          return this.planCost;
        }
      },
      upgradeForm: function () {
        return this.formType === "upgrade";
      },
    },
    methods: {
      buildRailsParamsHash: function () {
        return {
          user: {
            account_type: this.plan.id,
            age_group_id: this.ageGroup.id,
            birthday: this.birthday,
            email: this.email,
            ethnicity: this.ethnicity,
            first_name: this.firstName,
            gender: this.gender,
            ideal_weight: this.idealWeight,
            last_name: this.lastName,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            physical_activity: this.physicalActivity,
            timezone: this.timeZone,
            weight: this.weight,
            questionnaire_answer_attributes: this.buildQuestionnaireAnswerParams(),
          },
        };
      },
      buildRailsRegistrationParamsHash: function () {
        return {
          user: {
            birthday: this.fullBirthday,
            email: this.email,
            ethnicity: this.ethnicity,
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            timezone: this.timeZone,
          },
        };
      },
      buildRailsUpdateParamsHash: function () {
        let params = this.buildRailsParamsHash();
        delete params["user"]["password"];
        delete params["user"]["password_confirmation"];
        params.id = this.userId;
        return params;
      },
      buildQuestionnaireAnswerParams: function () {
        let params = {
          body_type: this.bodyType,
          face_shape: this.faceShape,
          food_cravings: this.foodCraving,
          steady_energy_level: this.energyLevel.code,
          morning_person: this.morningPerson.code,
          early_puberty: this.earlyOnsetPuberty.code,
          sluggish_metabolism: this.sluggishMetabolism.code,
          post_menopausal_body_type_2: this.postMenopausalCombo.code,
          difficulty_gaining_weight: this.difficultyGainingWeight.code,
          energy_fluctuations: this.energyFluctuations.code,
        };
        if (this.editForm) {
          params.id = this.questionnaire_answer.id;
          params.user_id = this.user.id;
        }
        return params;
      },
      calculatePortionsProfile: function () {
        return this.bodyType;
      },
      capitalize: function (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
      createPaymentMethod: function () {
        var self = this;
        this.stripeObject
          .createPaymentMethod({
            type: "card",
            card: this.credit_card,
            billing_details: {
              name: this.fullName,
            },
          })
          .then((result) => {
            if (result.error) {
              self.displayCardError(result);
            } else {
              if (this.selectedPaymentOption === "4 payments of $797*") {
                self.createSubscriptionSchedule({
                  customerId: self.stripeCustomerId,
                  paymentMethodId: result.paymentMethod.id,
                  userId: self.userId,
                  // TODO: switch "plan" name to "coachingGroup"
                  coachingGroup: self.plan.id,
                });
              } else if (
                this.selectedPaymentOption === "1 payment of $2,899*"
              ) {
                self.createSinglePaymentCoaching({
                  customerId: self.stripeCustomerId,
                  paymentMethodId: result.paymentMethod.id,
                  userId: self.userId,
                  // TODO: switch "plan" name to "coachingGroup"
                  coachingGroup: self.plan.id,
                });
              }
            }
          });
      },
      createStripeCustomer: function () {
        var self = this;
        axios
          .post("/stripe_create_customer", { user_id: this.userId })
          .then(
            function (response) {
              console.log(
                "response.customer_stripe_id: " +
                  response.data.customer_stripe_id
              );
              self.stripeCustomerId = response.data.customer_stripe_id;
              self.createPaymentMethod();
            },
            (error) => {
              console.log(error.response.data.errors);
              console.log("in createStripeCustomer error");
              self.displayErrorMessage(self, error.response.data.errors);
            }
          )
          .catch(function (error) {
            console.log("in createStripeCustomer catch");
            self.displayErrorMessage(self, error.response.data.errors);
          });
      },
      // get Stripe API KEY and setup Stripe instance
      configureStripe() {
        let self = this;
        axios
          .get("/stripe_publishable_key/")
          .then(function (response) {
            self.stripeAPIToken = response.data.stripe_publishable_key;
            self.stripeObject = Stripe(self.stripeAPIToken);
            // TODO refactor and add these in
            // this.elements = this.stripe.elements();
            // this.card = this.elements.create('card');
            // this.card.mount('#card-element');
            self.loadStripeCardElements(self);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      createSinglePayment: function ({ paymentMethodId, customerId, userId }) {
        console.log(
          "in create single payment: " + paymentMethodId + " " + customerId
        );
        var params = {
          customer_stripeid: customerId,
          payment_method_stripeid: paymentMethodId,
          user_id: userId,
        };
        var self = this;
        return (
          axios
            .post("/stripe_create_single_payment", params)
            .then(function (response) {
              console.log("first then - response: " + JSON.stringify(response));
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              console.log("second then - error check...");
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              console.log("third then - " + " " + JSON.stringify(result));
              self.onSinglePaymentComplete(self, result);
            })
            .catch(function (error) {
              console.log("in card error catch");
              self.displayErrorMessage(self, error);
            })
        );
      },
      createSinglePaymentCoaching: function ({
        customerId,
        paymentMethodId,
        userId,
        coachingGroup,
      }) {
        console.log(
          "in create single coaching payment: " +
            paymentMethodId +
            " " +
            customerId +
            " " +
            userId +
            " " +
            coachingGroup
        );
        var params = {
          customer_stripeid: customerId,
          payment_method_stripeid: paymentMethodId,
          user_id: userId,
          coaching_group: coachingGroup,
        };
        var self = this;
        return (
          axios
            .post("/stripe_create_single_payment_invoice", params)
            .then(function (response) {
              console.log("first then - response: " + JSON.stringify(response));
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              self.onSinglePaymentCoachingComplete(self, result);
            })
            .catch(function (error) {
              self.displayErrorMessage(self, error);
            })
        );
      },
      createSubscriptionSchedule: function ({
        customerId,
        paymentMethodId,
        userId,
        coachingGroup,
      }) {
        console.log(
          "in create subscription schedule: " +
            " " +
            customerId +
            " " +
            paymentMethodId +
            " " +
            coachingGroup
        );
        var params = {
          customer_stripeid: customerId,
          payment_method_stripeid: paymentMethodId,
          user_id: userId,
          coaching_group: coachingGroup,
        };
        var self = this;
        return (
          axios
            .post("/stripe_create_subscription_schedule", params)
            .then(function (response) {
              console.log("first then - response: " + JSON.stringify(response));
              console.log(
                "first then - response.subscription schedule: " +
                  JSON.stringify(response.data.subscription)
              );
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // Normalize the result to contain the object returned by Stripe.
            // Add the additional details we need.
            .then(function (result) {
              return {
                subscription: result.data.subscription,
                paymentMethodId: paymentMethodId,
              };
            })
            // If attaching this card to a Customer object succeeds,
            // but attempts to charge the customer fail, you
            // get a requires_payment_method error.
            .then(function (result) {
              return self.handleRequiresPaymentMethod(
                result.subscription,
                result.paymentMethodId
              );
            })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              self.onSubscriptionScheduleComplete(self, result);
            })
            .catch(function (error) {
              self.displayErrorMessage(self, error);
            })
        );
      },
      displayCardError: function (event) {
        // changeLoadingStatePrices(false);
        this.hideSpinnerDisablePaymentForm();
        let displayError = document.getElementById("card-element-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      },
      displayErrorMessage: function (self, message) {
        console.log(message);
        self.errorMessage = message;
        self.hasError = true;
        self.hideSpinnerDisablePaymentForm();
      },
      displaySpinnerDisablePaymentForm: function () {
        $("#submit-payment").attr("disabled", "disabled");
        this.credit_card.update({ disabled: true });
        $(".loader").show();
      },
      getAgeGroupLabel: function (ageGroupID) {
        let returnValue = null;
        for (let i = 0; i < this.ageGroupOptions.length; i++) {
          if (this.ageGroupOptions[i].id === ageGroupID) {
            returnValue = this.ageGroupOptions[i].label;
          }
        }
        return returnValue;
      },
      handleRequiresPaymentMethod: function (subscription, paymentMethodId) {
        console.log(
          "Inside handleRequiresPaymentMethod: " +
            " " +
            subscription +
            ", " +
            paymentMethodId
        );
        if (
          subscription.status === "active" ||
          subscription.status === "trialing"
        ) {
          // subscription is active, no customer actions required.
          console.log("subscription is active!");
          return { subscription, paymentMethodId };
        } else if (
          subscription.latest_invoice.payment_intent.status ===
          "requires_payment_method"
        ) {
          // Using localStorage to manage the state of the retry here,
          // feel free to replace with what you prefer.
          // Store the latest invoice ID and status.
          self.latestInvoiceId = subscription.latest_invoice.id;
          self.latestInvoicePaymentIntentStatus =
            subscription.latest_invoice.payment_intent.status;
          throw { error: { message: "Your card was declined." } };
        } else {
          return { subscription, paymentMethodId };
        }
      },
      // include Stripe.js dynamically
      includeStripe(URL, callback) {
        let documentTag = document,
          tag = "script",
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = "//" + URL;
        if (callback) {
          object.addEventListener(
            "load",
            function (e) {
              callback(null, e);
            },
            false
          );
        }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },
      initializeFormData: function () {
        if (this.newForm) {
          this.status = STATUS_REGISTERING;
        } else if (this.editForm) {
          this.status = STATUS_EDITING;
        } else if (this.upgradeForm) {
          this.status = STATUS_UPGRADING;
        }
      },
      initializeUserData: function () {
        this.ageGroup = {
          id: this.user["age_group_id"],
          label: this.getAgeGroupLabel(this.user["age_group_id"]),
        };
        (this.birthdayDay = this.user["birthday"]
          ? this.user["birthday"].split(" ")[0]
          : ""),
          (this.birthdayMonth = this.user["birthday"]
            ? this.user["birthday"].split(" ")[1]
            : ""),
          (this.ethnicity = this.user["ethnicity"]),
          (this.email = this.user["email"]);
        this.firstName = this.user["first_name"];
        this.gender = this.user["gender"];
        this.idealWeight = this.user["ideal_weight"];
        this.lastName = this.user["last_name"];
        this.physicalActivity = this.user["physical_activity"];
        this.timeZone = this.user["timezone"];
        this.userId = this.user["id"];
        this.weight = this.user["weight"];
        this.bodyType = this.questionnaire_answer
          ? this.questionnaire_answer.body_type
          : "";
        this.faceShape = this.questionnaire_answer
          ? this.questionnaire_answer.face_shape
          : "";
        this.foodCraving = this.questionnaire_answer
          ? this.questionnaire_answer.food_cravings
          : "";
        this.energyLevel = this.formatQuestionnaireAnswerAttr(
          "steady_energy_level"
        );
        this.morningPerson = this.formatQuestionnaireAnswerAttr(
          "morning_person"
        );
        this.earlyOnsetPuberty = this.formatQuestionnaireAnswerAttr(
          "early_puberty"
        );
        this.sluggishMetabolism = this.formatQuestionnaireAnswerAttr(
          "sluggish_metabolism"
        );
        this.postMenopausalCombo = this.formatQuestionnaireAnswerAttr(
          "post_menopausal_body_type_2"
        );
        this.difficultyGainingWeight = this.formatQuestionnaireAnswerAttr(
          "difficulty_gaining_weight"
        );
        this.energyFluctuations = this.formatQuestionnaireAnswerAttr(
          "energy_fluctuations"
        );
      },
      formatBooleanObject: function (value) {
        if (value) {
          return { label: "Yes", code: true };
        } else {
          return { label: "No", code: false };
        }
      },
      formatErrorMessage: function (attr, error) {
        attr = attr.replace(/questionnaire_answer./g, "").replace(/_/g, " ");
        return this.capitalize(attr) + " " + error;
      },
      formatQuestionnaireAnswerAttr: function (attribute) {
        return this.questionnaire_answer
          ? this.formatBooleanObject(this.questionnaire_answer[attribute])
          : "";
      },
      hideSpinnerDisablePaymentForm: function () {
        $("#submit-payment").attr("disabled", false);
        this.credit_card.update({ disabled: false });
        $(".loader").hide();
      },
      loadConfirmationPage: function () {
        window.location = `/stripe_coaching_confirmation`;
      },
      loadErrorPage: function () {
        window.location = `/stripe_error`;
      },
      loadHomePage: function () {
        window.location = `/`;
      },
      loadPaymentForm: function () {
        this.status = STATUS_COLLECTING_PAYMENT_INFO;
      },
      loadStripeCardElements: function (self) {
        const elements = self.stripeObject.elements();
        const credit_card = elements.create("card");
        credit_card.mount("#card-element");
        credit_card.on("change", function (event) {
          self.displayCardError(event);
        });
        self.credit_card = credit_card;
        self.stripeLoaded = true;
      },
      loadUserData: function (attribute) {
        if (this.editForm) {
          this.initializeUserData();
        } else if (this.upgradeForm) {
          this.plan = {
            label: this.capitalize(PLAN_PREMIUM),
            id: PLAN_PREMIUM,
          };
          this.userId = this.user["id"];
        }
      },
      loadUserShowPage: function (userId) {
        console.log("in user show page");
        window.location = "/users/" + userId;
      },
      onSubmitPaymentCollectionForm: function () {
        this.displaySpinnerDisablePaymentForm();
        this.createStripeCustomer();
      },
      onSubmitPlanSelectionForm: function () {
        this.loadPaymentForm();
      },
      onSubmitPortionsProfileQuestions: function () {
        let params = this.buildRailsUpdateParamsHash();
        let self = this;

        axios
          .put("/users", params)
          .then(
            (response) => {
              console.log(
                "update succeeded - user_id: " + response.data.user_id
              );

              if (self.newForm) {
                self.status = STATUS_PORTIONS_PROFILE_COMPLETE;
                self.loadConfirmationPage();
              } else if (self.editForm) {
                self.loadUserShowPage(response.data.user_id);
              }
            },
            (error) => {
              console.log(error.response.data);
              self.setUserErrors(error.response.data.errors);
            }
          )
          .catch((error) => {
            console.log("in catch: " + error);
          });
      },
      onSubmitRegistrationForm: function () {
        let params = this.buildRailsRegistrationParamsHash();
        var self = this;

        axios
          .post("/users", params)
          .then(
            (response) => {
              console.log("response.user_id: " + response.data.user_id);
              self.userId = response.data.user_id;
              // not signing in for now since email must be confirmed first
              // this.signInUser();
              self.status = STATUS_SELECTING_PLAN;
            },
            (error) => {
              console.log(error.response.data);
              self.setUserErrors(error.response.data.errors);
            }
          )
          .catch((error) => {
            console.log("in catch: " + error.response.data.errors);
            self.errorMessage = "An error occurred.  Please contact admin.";
          });
      },
      onRegistrationShortcut: function () {
        this.birthdayMonth = "Oct";
        this.birthdayDay = "27";
        this.email = "test@fake.com";
        this.ethnicity = "Asian";
        this.firstName = "Bob";
        this.lastName = "Smith";
        this.password = "password";
        this.passwordConfirmation = "password";
        this.timeZone = "Alaska";
      },
      onSinglePaymentComplete: function (self, result) {
        console.log(
          "reached single payment complete! - " + JSON.stringify(result)
        );
        self.credit_card.destroy();
        self.status = STATUS_PAYMENT_COMPLETE;
      },
      onSinglePaymentCoachingComplete: function (self, result) {
        console.log(
          "reached single payment coaching complete! - " +
            JSON.stringify(result)
        );
        self.credit_card.destroy();
        self.status = STATUS_SINGLE_PAYMENT_INVOICE_COMPLETE;
        self.loadConfirmationPage();
      },
      onSubscriptionScheduleComplete: function (self, result) {
        console.log(
          "reached subscription complete! - " + result.subscription.status
        );
        // remove Stripe credit card
        self.credit_card.destroy();
        // Payment was successful.
        if (
          result.subscription.status === "active" ||
          result.subscription.status === "trialing"
        ) {
          console.log("subscription is active! You can display last page");

          // display confirmation image
          self.status = STATUS_SUBSCRIPTION_SCHEDULE_COMPLETE;
          self.loadConfirmationPage();
        } else {
          self.loadErrorPage();
        }
      },
      // TODO Need to hook this up
      setBirthdayDayOptions: function (event) {
        console.log("value: " + event.target.value);
        switch (event.target.value) {
          case ("Jan", "Mar", "May", "July", "Aug", "Oct", "Dec"):
            if (!this.dayOptions.includes("31")) {
              this.dayOptions = this.originalDayOptions;
            }
          case ("Apr", "June", "Sept", "Nov"):
            this.dayOptions = this.originalDayOptions;
            this.dayOptions.splice(30, 1);
          case "Feb":
            this.dayOptions = this.originalDayOptions;
            this.dayOptions.splice(27, 4);
        }
      },
      signInData: function () {
        return {
          user: {
            remember_me: 1,
            password: this.password,
            email: this.email,
          },
        };
      },
      signInUser: function () {
        let self = this;
        this.$http.post("/users/sign_in.json", this.signInData()).then(
          (response) => {
            console.log("Signed in successfully");
            this.userSignedIn = true;
          },
          (response) => {
            console.log("Unable to signin" + JSON.stringify(response));
          }
        );
      },
      signOutUser: function () {
        console.log("in signout user");
        let self = this;
        axios.delete("/users/sign_out").then(
          (response) => {
            console.log("Signed out successfully");
          },
          (error) => {
            console.log("Unable to signout" + JSON.stringify(error));
          }
        );
      },
      setUserErrors: function (errors) {
        this.errors = errors;
        this.$scrollTo(this.errors);
      },
    },
  });

  var element = document.getElementById("questionnaire-coaching-app");

  if (element != null) {
    const QuestionnaireApp = new Vue({
      el: element,
      template: "#questionnaire-coaching-app-template",
      data: () => {
        return {
          message: "Vue is working",
        };
      },
    });
  }
});
