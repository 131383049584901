// This represents a third signup process that involves:
// 1. Group coaching programs
// 2. Multiple pricing options

import Vue from "vue/dist/vue.esm";

import vSelect from "vue-select";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueScrollTo from "vue-scrollto";
import axios from "axios";

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScrollTo);

Vue.component("v-select", vSelect);

document.addEventListener("turbolinks:load", () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";

  const STATUS_REGISTERING = "registering";
  const STATUS_SELECTING_PAYMENT_OPTION = "selectingPaymentOption";
  const STATUS_COLLECTING_PAYMENT_INFO = "collectingPaymentInfo";
  const STATUS_PAYMENT_COMPLETE = "paymentComplete";

  // for premium coaching sign up
  const STATUS_SINGLE_PAYMENT_INVOICE_COMPLETE = "singlePaymentInvoiceComplete";
  const STATUS_SUBSCRIPTION_SCHEDULE_COMPLETE = "subscriptionScheduleComplete";

  const QuestionnaireForm = Vue.component("registration-coaching-form", {
    template: "#registration-coaching-form-template",
    props: [
      "ageGroupOptions",
      "coachingProgram",
      "ethnicityOptions",
      "formType",
      "planOptions",
      "questionnaire_answer",
      "timeZoneOptions",
      "user",
    ],
    mounted: function () {
      this.initializeFormData();
    },
    updated: function () {
      if (
        this.status === STATUS_COLLECTING_PAYMENT_INFO &&
        !this.stripeLoaded
      ) {
        this.includeStripe(
          "js.stripe.com/v3/",
          function () {
            this.configureStripe();
          }.bind(this)
        );
      }
    },
    data: function () {
      return {
        errorMessage: "",
        errors: {},
        ethnicity: "",
        email: "",
        firstName: "",
        group: "",
        hasError: false,
        lastName: "",
        latestInvoiceId: "",
        latestInvoicePaymentIntentStatus: "",
        password: "",
        passwordConfirmation: "",
        paymentOption: "",
        promoCode: "",
        status: STATUS_REGISTERING,
        stripeAPIToken: "",
        stripeCustomerId: "",
        stripeLoaded: false,
        stripeObject: "",
        timeZone: "",
        userId: "",
        userSignedIn: false,
        weight: "",
        yesOrNoOptions: [
          { label: "Yes", code: true },
          { label: "No", code: false },
        ],
      };
    },
    computed: {
      appliedText: function () {
        if (this.promoCodeApplied) {
          return " Promo Applied";
        }
      },
      displayPaymentCollectionForm: function () {
        return this.status === STATUS_COLLECTING_PAYMENT_INFO;
      },
      displayPlanSelectionForm: function () {
        return this.status === STATUS_SELECTING_PAYMENT_OPTION;
      },
      displayRegistrationQuestions: function () {
        return this.status === STATUS_REGISTERING;
      },
      fullName: function () {
        return this.firstName + " " + this.lastName;
      },
      monthlyPaymentPrice: function () {
        return this.coachingProgram.monthly_price / 100;
      },
      newForm: function () {
        return this.formType === "new";
      },
      oneTimePrice: function () {
        return this.coachingProgram.one_time_price / 100;
      },
      paymentOptions: function () {
        const option1 = {
          label: "Lump sum: $" + this.oneTimePrice,
          id: "oneTimePrice",
        };
        const option2 = {
          label:
            "Payment plan: " +
            this.coachingProgram.monthly_payment_count +
            " monthly autopayments of $" +
            this.monthlyPaymentPrice,
          id: "monthlyPrice",
        };

        return [option1, option2];
      },
      paymentOptionSelectionFormComplete: function () {
        return this.paymentOption;
      },
      promoCodeApplied: function () {
        return false;
      },
      registrationFormComplete: function () {
        return (
          this.firstName &&
          this.email &&
          this.ethnicity &&
          this.lastName &&
          this.password &&
          this.passwordConfirmation &&
          this.timeZone
        );
      },
      totalBilled: function () {
        if (this.paymentOption.id === "oneTimePrice") {
          return this.oneTimePrice;
        } else {
          return this.monthlyPaymentPrice;
        }
      },
      upgradeForm: function () {
        return this.formType === "upgrade";
      },
    },
    methods: {
      buildRailsRegistrationParamsHash: function () {
        return {
          user: {
            email: this.email,
            ethnicity: this.ethnicity,
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            timezone: this.timeZone,
          },
        };
      },
      capitalize: function (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
      createPaymentMethod: function () {
        var self = this;
        this.stripeObject
          .createPaymentMethod({
            type: "card",
            card: this.credit_card,
            billing_details: {
              name: this.fullName,
            },
          })
          .then((result) => {
            if (result.error) {
              self.displayCardError(result);
            } else {
              self.processStripePayment({
                customerId: self.stripeCustomerId,
                paymentMethodId: result.paymentMethod.id,
                userId: self.userId,
                coachingProgramId: self.coachingProgram.id,
                paymentOption: self.paymentOption.id,
                monthlyPaymentCount: self.coachingProgram.monthly_payment_count,
              });
            }
          });
      },
      createStripeCustomer: function () {
        var self = this;
        axios
          .post("/stripe_create_customer", { user_id: this.userId })
          .then(
            function (response) {
              console.log(
                "response.customer_stripe_id: " +
                  response.data.customer_stripe_id
              );
              self.stripeCustomerId = response.data.customer_stripe_id;
              self.createPaymentMethod();
            },
            (error) => {
              console.log(error.response.data.errors);
              console.log("in createStripeCustomer error");
              self.displayErrorMessage(self, error.response.data.errors);
            }
          )
          .catch(function (error) {
            console.log("in createStripeCustomer catch");
            self.displayErrorMessage(self, error.response.data.errors);
          });
      },
      // get Stripe API KEY and setup Stripe instance
      configureStripe() {
        let self = this;
        axios
          .get("/stripe_publishable_key/")
          .then(function (response) {
            self.stripeAPIToken = response.data.stripe_publishable_key;
            self.stripeObject = Stripe(self.stripeAPIToken);
            self.loadStripeCardElements(self);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      processStripePayment: function ({
        customerId,
        paymentMethodId,
        userId,
        coachingProgramId,
        paymentOption,
        monthlyPaymentCount,
      }) {
        console.log(
          "in create subscription schedule: " +
            " " +
            customerId +
            " " +
            paymentMethodId +
            " " +
            coachingProgramId
        );
        var params = {
          customer_stripeid: customerId,
          payment_method_stripeid: paymentMethodId,
          user_id: userId,
          coaching_program_id: coachingProgramId,
          payment_option: paymentOption,
          monthly_payment_count: monthlyPaymentCount,
        };
        var self = this;
        return (
          axios
            .post("/stripe_process_stripe_payment", params)
            .then(function (response) {
              console.log("first then - response: " + JSON.stringify(response));
              console.log(
                "first then - response.subscription schedule: " +
                  JSON.stringify(response.data.subscription)
              );
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // FIXME
            // Normalize the result to contain the object returned by Stripe.
            // Add the additional details we need.
            // .then(function (result) {
            //   return {
            //     subscription: result.data.subscription,
            //     paymentMethodId: paymentMethodId,
            //   };
            // })
            // FIXME
            // If attaching this card to a Customer object succeeds,
            // but attempts to charge the customer fail, you
            // get a requires_payment_method error.
            // .then(function (result) {
            //   return self.handleRequiresPaymentMethod(
            //     result.subscription,
            //     result.paymentMethodId
            //   );
            // })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              self.onStripePaymentComplete(self, result);
            })
            .catch(function (error) {
              self.displayErrorMessage(self, error);
            })
        );
      },
      displayCardError: function (event) {
        // changeLoadingStatePrices(false);
        this.hideSpinnerDisablePaymentForm();
        let displayError = document.getElementById("card-element-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      },
      displayErrorMessage: function (self, message) {
        console.log(message);
        self.errorMessage = message;
        self.hasError = true;
        self.hideSpinnerDisablePaymentForm();
      },
      displaySpinnerDisablePaymentForm: function () {
        $("#submit-payment").attr("disabled", "disabled");
        this.credit_card.update({ disabled: true });
        $(".loader").show();
      },
      handleRequiresPaymentMethod: function (subscription, paymentMethodId) {
        console.log(
          "Inside handleRequiresPaymentMethod: " +
            " " +
            subscription +
            ", " +
            paymentMethodId
        );
        if (
          subscription.status === "active" ||
          subscription.status === "trialing"
        ) {
          // subscription is active, no customer actions required.
          console.log("subscription is active!");
          return { subscription, paymentMethodId };
        } else if (
          subscription.latest_invoice.payment_intent.status ===
          "requires_payment_method"
        ) {
          // Using localStorage to manage the state of the retry here,
          // feel free to replace with what you prefer.
          // Store the latest invoice ID and status.
          self.latestInvoiceId = subscription.latest_invoice.id;
          self.latestInvoicePaymentIntentStatus =
            subscription.latest_invoice.payment_intent.status;
          throw { error: { message: "Your card was declined." } };
        } else {
          return { subscription, paymentMethodId };
        }
      },
      // include Stripe.js dynamically
      includeStripe(URL, callback) {
        let documentTag = document,
          tag = "script",
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = "//" + URL;
        if (callback) {
          object.addEventListener(
            "load",
            function (e) {
              callback(null, e);
            },
            false
          );
        }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },
      initializeFormData: function () {
        this.status = STATUS_REGISTERING;
      },
      formatErrorMessage: function (attr, error) {
        attr = attr.replace(/questionnaire_answer./g, "").replace(/_/g, " ");
        return this.capitalize(attr) + " " + error;
      },
      hideSpinnerDisablePaymentForm: function () {
        $("#submit-payment").attr("disabled", false);
        this.credit_card.update({ disabled: false });
        $(".loader").hide();
      },
      loadConfirmationPage: function () {
        window.location =
          `/stripe_coaching_confirmation?confirmation_image=` +
          this.coachingProgram.confirmation_image;
      },
      loadErrorPage: function () {
        window.location = `/stripe_error`;
      },
      loadHomePage: function () {
        window.location = `/`;
      },
      loadPaymentForm: function () {
        this.status = STATUS_COLLECTING_PAYMENT_INFO;
      },
      loadStripeCardElements: function (self) {
        const elements = self.stripeObject.elements();
        const credit_card = elements.create("card");
        credit_card.mount("#card-element");
        credit_card.on("change", function (event) {
          self.displayCardError(event);
        });
        self.credit_card = credit_card;
        self.stripeLoaded = true;
      },
      loadUserShowPage: function (userId) {
        console.log("in user show page");
        window.location = "/users/" + userId;
      },
      onSubmitPaymentCollectionForm: function () {
        this.displaySpinnerDisablePaymentForm();
        this.createStripeCustomer();
      },
      onSubmitPaymentOptionSelectionForm: function () {
        this.loadPaymentForm();
      },
      onSubmitRegistrationForm: function () {
        let params = this.buildRailsRegistrationParamsHash();
        var self = this;

        axios
          .post("/users", params)
          .then(
            (response) => {
              self.userId = response.data.user_id;
              self.status = STATUS_SELECTING_PAYMENT_OPTION;
            },
            (error) => {
              console.log(error.response.data);
              self.setUserErrors(error.response.data.errors);
            }
          )
          .catch((error) => {
            console.log("in catch: " + error.response.data.errors);
            self.errorMessage = "An error occurred.  Please contact admin.";
          });
      },
      onRegistrationShortcut: function () {
        this.email = "test@fake.com";
        this.ethnicity = "Asian";
        this.firstName = "Bob";
        this.lastName = "Smith";
        this.password = "password";
        this.passwordConfirmation = "password";
        this.timeZone = "Alaska";
      },
      onStripePaymentComplete: function (self, result) {
        console.log(
          "reached stripe payment complete! - " + JSON.stringify(result)
        );
        // remove Stripe credit card
        self.credit_card.destroy();
        // Payment was successful.
        if (true) {
          console.log("subscription is active! You can display last page");

          // display confirmation image
          self.status = STATUS_PAYMENT_COMPLETE;
          self.loadConfirmationPage();
        } else {
          self.loadErrorPage();
        }
      },
      signInData: function () {
        return {
          user: {
            remember_me: 1,
            password: this.password,
            email: this.email,
          },
        };
      },
      setUserErrors: function (errors) {
        this.errors = errors;
        this.$scrollTo(this.errors);
      },
    },
  });

  var element = document.getElementById("registration-coaching-app");

  if (element != null) {
    const RegistrationCoachingApp = new Vue({
      el: element,
      template: "#registration-coaching-app-template",
      data: () => {
        return {
          message: "Vue is working",
        };
      },
    });
  }
});
