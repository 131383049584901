// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("chartkick");
require("chart.js");
require("packs/add_food");
require("packs/autocomplete");
require("packs/questionnaire");
require("packs/questionnaire-coaching");
require("packs/registration-coaching");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
Vue.use(TurbolinksAdapter);

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  var btn = document.querySelector('button#btn-report-go');
  if (btn) {
    btn.onclick = function(e) {
      var partner_id = this.form.partner.value;
      if (partner_id) {
        window.location.href = '/partners/' + partner_id + '/dashboard';
      }
    };
  }

  var submenuItems = document.querySelectorAll('.sub-nav-item');
  submenuItems.forEach((item) => {
    var title = item.querySelector('.nav-title');
    title.addEventListener('click', function(e) {
      var isExpanded = $(item).hasClass('expanded');
      submenuItems.forEach((other) => {
        $(other).removeClass('expanded');
      });
      if (!isExpanded) {
        $(item).addClass('expanded');
      }
    });
  });

  var tutorialColumns = document.querySelectorAll('.tutorials-column');
  tutorialColumns.forEach((item) => {
    var title = item.querySelector('h6');
    title.addEventListener('click', function(e) {
      var isExpanded = $(item).hasClass('expanded');
      tutorialColumns.forEach((other) => {
        $(other).removeClass('expanded');
      });
      if (!isExpanded) {
        $(item).addClass('expanded');
      }
    });
  });

});

console.log("Hello World from Webpacker");
