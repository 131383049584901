import Vue from 'vue/dist/vue.esm';

import axios from 'axios';
import VueAutosuggest from "vue-autosuggest";
import vSelect from 'vue-select';
import { EventBus } from './event-bus.js';

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(VueAutosuggest);
Vue.component('v-select', vSelect)

document.addEventListener('turbolinks:load', () => {

  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  const STATE_CREATE = "create";
  const STATE_UPDATE = "update";
  const FOOD_FILTER_ALL = "food_filter_all";
  const FOOD_FILTER_FAVORITES = "food_filter_favorites";
  const FOOD_FILTER_RECIPES = "food_filter_recipes";

  const AutoCompleteForm = Vue.component('autocomplete-form', {
    template: '#autocomplete-form-template',
    props: [
      'eatenDaysAgo', 'association'
    ],
    mounted: function() {
      this.clearForm();
      EventBus.$on('editFoodClicked', this.onEditFoodClicked);
      EventBus.$on('foodSearchClicked', this.setModalFoodSearch);
      EventBus.$on('favoritesClicked', this.setModalFavorites);
      EventBus.$on('recipesClicked', this.setModalRecipes);
    },
    watch: {
      selected: function(val) {
        if(this.foodFilterGroup === FOOD_FILTER_FAVORITES) {
          this.getPortionLabels();
          this.getFoodData();
        }
      }
    },
    data: function() {
      return {
        addToFavoritesFailure: '',
        addToFavoritesSuccess: '',
        defaultServingUnitOptions: [],
        deleteFromFavoritesFailure: '',
        deleteFromFavoritesSuccess: '',
        foodCategoryId: '',
        foodCategoryImageSRC: '',
        foodDropdownOptions: [],
        foodFilterGroup: '',
        filteredOptions: [],
        historyId: '',
        ingredientId: '',
        inputProps: {
          id: "food",
          onInputChange: this.onInputChange,
          placeholder: "Enter food..."
        },
        query: '',
        recipeId: '',
        portionLabels: '',
        selected: '',
        servingSize: '',
        servingUnit: '',
        servingUnitOptions: [],
        state: STATE_CREATE
      }
    },
    computed: {
      buildCreateParams: function() {
        if(this.association === "history") {
          return this.buildCreateHistoryParams;
        } else {
          return this.buildCreateIngredientParams;
        }
      },
      buildCreateHistoryParams: function() {
        if(this.foodFilterGroup === FOOD_FILTER_RECIPES) {
          return {
            food: this.selected,
            size: this.servingSize,
            days_ago: this.eatenDaysAgo
          }
        } else {
          return {
            food: this.selected,
            unit: this.servingUnit,
            size: this.servingSize,
            days_ago: this.eatenDaysAgo
          }
        }
      },
      buildCreateIngredientParams: function() {
        return {
          recipe_id: this.recipeId,
          food: this.selected,
          size: this.servingSize,
          unit: this.servingUnit
        }
      },
      buildUpdateParams: function() {
        if(this.association == "history") {
          return this.buildUpdateHistoryParams;
        } else {
          return this.buildUpdateIngredientParams;
        }
      },
      buildUpdateHistoryParams: function() {
        return {
          id:   this.historyId,
          food: this.selected,
          unit: this.servingUnit,
          size: this.servingSize
        }
      },
      buildUpdateIngredientParams: function() {
        return {
          id:   this.ingredientId,
          recipe_id: this.recipeId,
          food: this.selected,
          size: this.servingSize,
          unit: this.servingUnit
        }
      },
      disableDeleteFromFavorites: function() {
        if(this.selected) {
          return false;
        } else {
          return true;
        }
      },
      disableFavorites: function() {
        if(this.selected && this.servingUnit) {
          return false;
        } else {
          return true;
        }
      },
      displayAutosuggest: function() {
        return this.foodFilterGroup === FOOD_FILTER_ALL;
      },
      displayDeleteFromFavorites: function() {
        return this.foodFilterGroup === FOOD_FILTER_FAVORITES;
      },
      displayFoodSelect: function() {
        return this.foodFilterGroup === FOOD_FILTER_FAVORITES || this.foodFilterGroup === FOOD_FILTER_RECIPES;
      },
      displayServingUnitSelect: function() {
        if(this.foodFilterGroup === FOOD_FILTER_RECIPES) {
          return false
        } else {
          return true;
        }
      },
      displayFoodCategoryImage: function() {
        return !!this.foodCategoryImageSRC;
      },
      isCreating: function() {
        return this.state === STATE_CREATE;
      },
      isUpdating: function() {
        return this.state === STATE_UPDATE;
      },
      isFormValid: function() {
        if(this.foodFilterGroup === FOOD_FILTER_RECIPES) {
          return this.selected && this.servingSize;
        } else {
          return this.selected && this.servingSize && this.servingUnit;
        }
      },
      saveFoodUrl: function() {
        if(this.association === "history") {
          return "/history";
        } else {
          if(this.isCreating) {
            return "/ingredients";
          } else {
            return "/ingredients/" + this.ingredientId;
          }
        }
      }
    },
    methods: {
      clearForm: function() {
        this.selected = '';
        this.query = '';
        this.servingSize = '';
        this.foodCategoryId = '';
        this.foodCategoryImageSRC = ''
        this.addToFavoritesFailure = '';
        this.addToFavoritesSuccess = '';
        this.deleteFromFavoritesFailure = '';
        this.deleteFromFavoritesSuccess = '';
        this.servingUnitOptions = this.defaultServingUnitOptions;
        this.state = STATE_CREATE;
      },
      clearSuggestions: function() {
        this.filteredOptions = [];
      },
      createHistoryOrIngredient: function() {
        let params = this.buildCreateParams;
        let url = this.saveFoodUrl;
        let self = this;

        axios
          .post(url, params)
          .then((response) => {
            console.log('History or Ingredient created! - response: ' + JSON.stringify(response.data));
            $('#myModal').modal('hide');
            EventBus.$emit('newFoodAdded', response.data);
            self.clearForm();
          }, error => {
            console.log(error.response.data);
            alert('Unable to add food');
          })
          .catch(error => {
            console.log("in createHistory catch: " + error);
          });
      },
      getFavoritesFoodData: function() {
        var self = this;
        axios
          .get("/history/favorites")
          .then(function (response) {
            self.foodDropdownOptions = response.data.foods;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      getFoodData: function() {
        var self = this;
        axios
          .get("/foods/" + this.selected)
          .then(function (response) {
            self.selectedFood = response.data.food;
            self.foodCategoryId = self.selectedFood.food_category_id
            self.selectFoodCategoryImageSRC();
          }, error => {
            console.log("in getFoodData error: " + error);
          })
          .catch(function (error) {
            console.log("in getFoodData catch: " + error);
          });
      },
      getRecipesFoodData: function() {
        var self = this;
        axios
          .get("/recipes")
          .then(function (response) {
            self.foodDropdownOptions = response.data.recipes;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      // for autocomplete input
      getAutoCompleteFoodData: function() {
        var self = this;
        axios
          .get("/foods_autocomplete/" + this.query)
          .then(function (response) {
            // return array is contains food names
            var food_options = response.data.foods;

            // const filteredData = food_options.filter(item => {
            //   return item.toLowerCase().indexOf(self.query.toLowerCase()) > -1;
            // }).slice(0, self.limit);
            const filteredData = food_options.slice(0, self.limit);

            self.filteredOptions = [{
              data: filteredData
            }];

          })
          .catch(function (error) {
            console.log(error);
          });
      },
      getPortionLabels: function(defaultServingUnit="") {
        var self = this;
        axios
          .get('/portion_labels/', { params: { food_name: this.selected } })
          .then(function (response) {
            var portion_labels = response.data.portion_labels;
            self.servingUnitOptions = portion_labels;

            if(defaultServingUnit) {
              self.servingUnit = defaultServingUnit
            } else {
              self.servingUnit = portion_labels[0]
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      onClick: function(item) {
        // event fired when clicking on the input
      },
      onClickAddToFavorites: function(e) {
        e.preventDefault();
        let params = { food_name: this.selected, portion_label: this.servingUnit };
        let self = this;
        axios
          .post("/favorite_foods", params)
          .then((response) => {
            if(response.data.status === 200) {
              this.addToFavoritesSuccess = response.data.message;
            } else {
              this.addToFavoritesFailure = response.data.message;
            }
          }, error => {
            console.log(error.response.data);
            this.addToFavoritesFailure = error.response.data.errors;
          })
          .catch(error => {
            console.log("in onClickAddToFavorites catch: " + error);
          });
      },
      onClickDeleteFromFavorites: function(e) {
        e.preventDefault();
        let self = this;
        axios
          .delete("/favorite_foods/" + this.selected)
          .then((response) => {
            this.deleteFromFavoritesSuccess = response.data.message;
            for (let i = 0; i < this.foodDropdownOptions.length; i++) {
              if(this.foodDropdownOptions[i] === self.selected) {
                this.$delete(this.foodDropdownOptions, i);
              }
            }
            self.selected = '';
          }, error => {
            console.log(error.response.data);
            this.deleteFromFavoritesFailure = error.response.data.errors;
          })
          .catch(error => {
            console.log("in onClickDeleteFromFavorites catch: " + error);
          });
      },
      onEditFoodClicked: function(response) {
        if(this.association === "history") {
          this.setHistory(response);

          if(response.recipe_id) {
            this.foodFilterGroup = FOOD_FILTER_RECIPES;
          } else {
            this.foodFilterGroup = FOOD_FILTER_ALL;
          }
        } else if (this.association === "recipe") {
          this.setIngredient(response);
          this.foodFilterGroup = FOOD_FILTER_ALL;
        }
        this.state = STATE_UPDATE;
        this.getPortionLabels(response.unit);
        $('#myModal').modal('show');
      },
      onSelect: function(option) {
        this.selected = option.item;
        this.getPortionLabels();
        this.getFoodData();
      },
      onSaveChangesClick: function() {
        if(this.isCreating) {
          this.createHistoryOrIngredient();
        } else {
          this.updateHistoryOrIngredient();
        }
      },
      onInputChange: function(text) {
        if (text === '' || text === undefined) {
          this.clearSuggestions();
          return;
        }

        this.query = text;
        this.getAutoCompleteFoodData();
      },
      selectFoodCategoryImageSRC: function() {
        var src = ''
        switch(this.foodCategoryId.toString()) {
          case "1":
          case "2":
            src = '/images/UNIT_OF_MEASURE_TABLE_MEAT_AND_FISH.png';
            break;
          default:
            src = '/images/UNIT_OF_MEASURE_TABLE_ALL.png'

        }
        this.foodCategoryImageSRC = src;
      },
      setModalFavorites: function(response) {
        this.clearForm();
        this.foodFilterGroup = FOOD_FILTER_FAVORITES;
        this.getFavoritesFoodData();
        if(response.recipe_id) {
          this.recipeId = response.recipe_id;
        }
      },
      setModalFoodSearch: function(response) {
        this.clearForm();
        this.foodFilterGroup = FOOD_FILTER_ALL;
        if(response.recipe_id) {
          this.recipeId = response.recipe_id;
        }
      },
      setModalRecipes: function() {
        this.clearForm();
        this.foodFilterGroup = FOOD_FILTER_RECIPES;
        this.getRecipesFoodData();
      },
      setStandardAttributes: function(response) {
        this.query = response.food_name;
        this.selected = response.food_name;
        this.servingUnit = response.unit;
        this.servingSize = response.portion_size;
        if(response.food) {
          this.foodCategoryId = response.food.food_category_id;
        }
        this.selectFoodCategoryImageSRC();
      },
      setHistory: function(response) {
        this.historyId = response.id;
        this.setStandardAttributes(response);
      },
      setIngredient: function(response) {
        this.ingredientId = response.id;
        this.recipeId = response.recipe_id;
        this.setStandardAttributes(response);
      },
      updateHistoryOrIngredient: function() {
        let params = this.buildUpdateParams;
        let url = this.saveFoodUrl;
        let self = this;

        axios
          .put(url, params)
          .then((response) => {
            console.log('History or Ingredient updated! - response: ' + JSON.stringify(response.data));
            $('#myModal').modal('hide');
            EventBus.$emit('foodUpdated', response.data);
            self.clearForm();
          }, error => {
            console.log(error.response.data);
            alert('Unable to update food');
          })
          .catch(error => {
            console.log("in updateHistory catch: " + error);
          });
      }
    }
  });

  var element = document.getElementById("autocomplete-app");

  if (element != null) {
    const AutocompleteApp = new Vue({
      el: element,
      data: () => {
        return {
          message: "Vue is working"
        }
      }
    })
  }
})
