import Vue from 'vue/dist/vue.esm';

import vSelect from 'vue-select';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import axios from 'axios';

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueScrollTo)

Vue.component('v-select', vSelect)

document.addEventListener('turbolinks:load', () => {

  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  const STATUS_REGISTERING = 'registering';
  const STATUS_SELECTING_PLAN = 'selectingPlan';
  const STATUS_COLLECTING_PAYMENT_INFO = 'collectingPaymentInfo';
  const STATUS_PAYMENT_COMPLETE = 'paymentComplete';
  const STATUS_PORTIONS_PROFILE_COMPLETE = 'portionsProfileComplete';
  const STATUS_EDITING = 'editing';
  const STATUS_UPGRADING = 'upgrading';

  const PLAN_BASIC = 'basic';
  const PLAN_PREMIUM = 'premium';

  const FOOD_CRAVINGS = [
    {id: 'Salty', value: 'Salty', label: 'Salty and fatty, like meat, hard cheeses, and butter.'},
    {id: 'Spicy', value: 'Spicy', label: 'Spicy (like Thai or Mexican), fried, and rich, creamy foods.'},
    {id: 'Dairy', value: 'Dairy', label: 'Dairy foods, such as yogurt, cheese, and cottage cheese.'},
    {id: 'Sweet', value: 'Sweet', label: 'Sweets like cake, cookies, and chocolate, plus caffeinated drinks.'}
  ];

  const OPTION_NONE_RELEVANT = {
    code: '',
    label: 'None of these are relevant'
  };

  const QuestionnaireForm = Vue.component('questionnaire-form', {
    template: '#questionnaire-form-template',
    props: [
      'ageGroupOptions',
      'ethnicityOptions',
      'formType',
      'partnerCode',
      'planOptions',
      'priceOptions',
      'questionnaire_answer',
      'timeZoneOptions',
      'user'
    ],
    mounted: function() {
      this.initializeFormData();
      this.loadUserData();
      if (this.status === STATUS_REGISTERING) {
        this.loadStripe();
      }
    },
    updated: function () {
      if (this.status === STATUS_COLLECTING_PAYMENT_INFO) {
        this.loadStripe();
      }
    },
    data: function() {
      return {
        ageGroup: {
          label: '',
          id: ''
        },
        birthdayMonth: '',
        birthdayDay: '',
        bodyType: '',
        creditCardComplete: false,
        difficultyGainingWeight: '',
        dayOptions: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31'
        ],
        disclaimer: {
          read: true,
          accepted: false
        },
        energyFluctuations: '',
        errorMessage: '',
        errors: {},
        ethnicity: '',
        femaleBodyTypeOptions: ['Body Type 1', 'Body Type 2', 'Body Type 3', 'Body Type 4'],
        earlyOnsetPuberty: '',
        email: '',
        energyLevel: '',
        firstName: '',
        faceShape: '',
        foodCraving: null,
        femaleFaceShapeOptions: [
          'Square shaped, possibly with some light masculine qualities.',
          'Delicate features with feminine qualities, slightly small in proportion to body.',
          'Round, with the look of "baby fat" that makes you look younger than you are, slightly large in proportion to your body.',
          'Long and slender (oval).'
        ],
        femaleFoodCravingOptions: [
          FOOD_CRAVINGS.find(c => c.id === 'Salty'),
          FOOD_CRAVINGS.find(c => c.id === 'Spicy'),
          FOOD_CRAVINGS.find(c => c.id === 'Dairy'),
          FOOD_CRAVINGS.find(c => c.id === 'Sweet')
        ],
        femaleQuestion3: null,
        femaleQuestion3Options: [
          {
            code: 'easy_build_muscle',
            label: "It’s easy for me to build muscle, and I can maintain a muscular body even if I don’t work out for a while"
          },
          {
            code: 'slim_waist_big_hips',
            label: 'My waist stays slim and flat while my hips, buttocks and thighs are proportionally larger than the rest of me'
          },
          {
            code: 'gain_weight_all_over',
            label: "I gain weight all over – arms, torso, legs -- and all I have to do is look at food to gain weight"
          },
          {
            code: 'small_boned',
            label: 'I am "small boned" and slender, but have abdominal fat that makes me look "skinny fat"'
          },
          {
            code: 'arms_shoulders_slender',
            label: 'My arms, shoulders and chest are slender in proportion to my waist and thighs, where fat collects'
          },
          OPTION_NONE_RELEVANT
        ],
        femaleQuestion4: null,
        femaleQuestion4Options: [
          {
            code: 'straight_waistline',
            label: "I've never had a defined waistline; my middle is just straight up and down"
          },
          {
            code: 'pear_hourglass_shape',
            label: 'I have a classic hourglass shape, which would look great if not for my big hips and bottom'
          },
          {
            code: 'fluid_retention',
            label: 'I have had problems with fluid retention (unrelated to medication side effects) that make me look "puffy"'
          },
          {
            code: 'difficulty_gaining_weight',
            label: 'I struggle to keep weight on'
          },
          OPTION_NONE_RELEVANT
        ],
        femaleQuestion5: null,
        femaleQuestion5Options: [
          {
            code: 'weight_tank',
            label: 'When I gain fat, it all goes above my waist and my neck and I look a bit like a "tank." It never seems to go to my legs'
          },
          {
            code: 'hips_inflated',
            label: 'Even at my heaviest, my arms, waist and lower legs remained slim while my hips, buttocks and thighs seem to inflate like a balloon'
          },
          {
            code: 'overweight_most_life',
            label: 'I still have "baby fat" - all over my body and face!'
          },
          {
            code: 'no_good_muscles',
            label: 'When I work out, even if I get toned, it’s hard to get big, defined muscles that make me look like an athlete'
          },
          OPTION_NONE_RELEVANT
        ],
        gender: '',
        genderOptions: ['M', 'F'],
        hasError: false,
        idealWeight: '',
        insulinResistance: '',
        lastName: '',
        latestInvoiceId: '',
        latestInvoicePaymentIntentStatus: '',
        maleBodyTypeOptions: ['Body Type 1', 'Body Type 3', 'Body Type 4'],
        maleFaceShapeOptions: [
          'Square shaped',
          'Round, with the look of "baby fat" that makes you look younger than you are, slightly large in proportion to your body',
          'Long and slender (oval)'
        ],
        maleFoodCravingOptions: [
          FOOD_CRAVINGS.find(c => c.id === 'Salty'),
          FOOD_CRAVINGS.find(c => c.id === 'Dairy'),
          FOOD_CRAVINGS.find(c => c.id === 'Sweet')
        ],
        maleQuestion3: null,
        maleQuestion3Options: [
          {
            code: 'easy_build_muscle',
            label: "It’s easy for me to build muscle, and I can maintain a muscular body even if I don’t work out for a while"
          },
          {
            code: 'gain_weight_all_over',
            label: "I gain weight all over – arms, torso, legs -- and all I have to do is look at food to gain weight"
          },
          {
            code: 'difficulty_gaining_weight',
            label: 'I struggle to keep weight on; sometimes I feel like I look like a "90-pound weakling"'
          },
          OPTION_NONE_RELEVANT
        ],
        maleQuestion4: null,
        maleQuestion4Options: [
          {
            code: 'weight_tank',
            label: 'When I gain fat, it all goes above my waist and my neck and I look a bit like a "tank." It never seems to go to my legs'
          },
          {
            code: 'overweight_most_life',
            label: 'I still have "baby fat" - all over my body and face!'
          },
          {
            code: 'no_good_muscles',
            label: 'When I work out, even if I get toned, it’s hard to get big, defined muscles that make me look like an athlete'
          },
          OPTION_NONE_RELEVANT
        ],
        monthOptions: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        morningPerson: '',
        originalDayOptions: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31'
        ],
        password: '',
        passwordConfirmation: '',
        physicalActivity: '',
        physicalActivityOptions: ['Sedentary/Light', 'Moderate', 'Heavy'],
        // the only plan available right now
        plan: {
          id: PLAN_PREMIUM,
          label: 'Premium'
        },
        postMenopausalCombo: '',
        discount: 0,
        joinDiscount: 0,
        promoCode: '',
        promoCodeValid: undefined,
        sluggishMetabolism: '',
        status: STATUS_REGISTERING,
        stripeAPIToken: '',
        stripeCustomerId: '',
        stripeLoaded: false,
        stripeObject: '',
        submitting: false,
        timeZone: '',
        userId: '',
        userSignedIn: false,
        weight: '',
        yesOrNoOptions: [
          { label: 'Yes', code: true },
          { label: 'No', code: false }
        ]
      }
    },
    watch: {
      isMale: function(val) {
        if (val === false) {
          this.maleQuestion3 = null;
          this.maleQuestion4 = null;
        }
      },
      isFemale: function(val) {
        if (val === false) {
          this.femaleQuestion3 = null;
          this.femaleQuestion4 = null;
          this.femaleQuestion5 = null;
        }
      },
    },
    computed: {
      canValidatePromoCode: function() {
        return (this.promoCode !== '');
      },
      promoCodeStatus: function() {
        switch (this.promoCodeValid) {
          case false:
            return 'Unknown code';
          case undefined:
          default:
            return '';
        }
      },
      appliedText: function() {
        if(this.promoCodeApplied) {
          return " Promo Applied"
        }
      },
      basicPlan: function() {
        return this.plan.id === PLAN_BASIC
      },
      displayFemaleQuestionnaire: function() {
        return this.isFemale;
      },
      displayMaleQuestionnaire: function() {
        return this.isMale;
      },
      displayDisclaimer: function() {
        return this.status === STATUS_REGISTERING;
      },
      displayPaymentCollectionForm: function() {
        return this.status === STATUS_REGISTERING || this.status === STATUS_COLLECTING_PAYMENT_INFO;
      },
      displayPlanSelectionForm: function() {
        return this.status === STATUS_SELECTING_PLAN || this.status === STATUS_UPGRADING;
      },
      displaySignupSuccessScreen: function() {
        return this.status === STATUS_PAYMENT_COMPLETE;
      },
      displayPortionsProfileQuestions: function() {
        return this.status === STATUS_EDITING;
      },
      displayRegistrationQuestions: function() {
        return this.status === STATUS_REGISTERING ||
          this.status === STATUS_EDITING;
      },
      displayUpgradingPlanSelectionHeader: function() {
        return this.status === STATUS_UPGRADING;
      },
      editForm: function () {
        return this.formType === 'edit';
      },
      femaleQuestionnaireComplete: function() {
        return this.generalQuestionnaireComplete &&
          this.femaleQuestion3 &&
          this.femaleQuestion4 &&
          this.femaleQuestion5;
          //this.earlyOnsetPuberty &&
          //this.postMenopausalCombo;
      },
      fullBirthday: function() {
        return this.birthdayMonth + ' ' + this.birthdayDay;
      },
      fullName: function() {
        return this.firstName + ' ' + this.lastName;
      },
      generalQuestionnaireComplete: function() {
        let complete =
          this.ageGroup &&
          //this.birthdayDay &&
          //this.birthdayMonth &&
          this.bodyType &&
          // this.difficultyGainingWeight &&
          this.email &&
          // this.energyLevel &&
          this.ethnicity &&
          this.firstName &&
          // this.faceShape &&
          this.foodCraving &&
          this.gender &&
          this.idealWeight &&
          this.lastName &&
          this.physicalActivity &&
          this.timeZone &&
          this.weight;

        /*
        complete = complete && (
          this.morningPerson &&
          this.sluggishMetabolism
        );
        */

        // if BodyType = 1, question about High Sugar / Insulin resistance
        // becomes required
        if (['Body Type 1'].includes(this.bodyType)) {
          if (!this.insulinResistance) {
            return false;
          }
        }

        if (this.newForm) {
          return complete && this.password && this.passwordConfirmation;
        } else if (this.editForm){
          return complete;
        }
      },
      isFemale: function() {
        return this.gender === 'F';
      },
      isMale: function() {
        return this.gender === 'M';
      },
      maleQuestionnaireComplete: function() {
        return this.generalQuestionnaireComplete &&
          this.maleQuestion3 &&
          this.maleQuestion4;
          //this.energyFluctuations;
      },
      newForm: function() {
        return this.formType === 'new';
      },
      joinFee: function() {
        const price = (this.priceOptions || {}).registration;
        return price ? (parseFloat(price.amount) * 0.01) : 99.00;
      },
      planCost: function() {
        if (this.basicPlan) {
          return 0;
        }
        if (this.premiumPlan) {
          const price = (this.priceOptions || {}).monthly;
          return price ? (parseFloat(price.amount) * 0.01) : 12.99;
        }
        return 0;
      },
      planSelectionFormComplete: function() {
        return this.plan;
      },
      planSummary: function() {
        if(this.basicPlan) {
          return "Basic"
        } else if(this.premiumPlan) {
          return "Premium"
        } else {
          return "No Plan Selected"
        }
      },
      premiumMonthly: function() {
        return this.premiumPlan && this.monthly;
      },
      premiumPlan: function() {
        return this.plan.id === PLAN_PREMIUM;
      },
      promoCodeApplied: function() {
        return this.promoCode === 'BETATEST' &&
          this.plan.id === "premium" &&
          this.paymentSchedule.id === "Every 3 Months";
      },
      questionnaireComplete: function() {
        if(this.isMale) {
          return this.maleQuestionnaireComplete;
        } else {
          return this.femaleQuestionnaireComplete;
        }
      },

      fullSignupFormComplete: function() {
        return !!this.firstName.length &&
          !!this.email.length &&
          !!this.ethnicity.length &&
          !!this.lastName.length &&
          !!this.password.length &&
          !!this.passwordConfirmation.length &&
          !!this.timeZone.length &&
          this.disclaimer.accepted &&
          this.creditCardComplete;
      },

      registrationFormComplete: function() {
        return this.birthdayDay && this.birthdayMonth && this.firstName && this.email && this.ethnicity && this.lastName && this.password && this.passwordConfirmation && this.timeZone;
      },
      submitButtonText: function() {
        return this.newForm ? "Create My Portion's Profile" : "Update Profile";
      },
      totalBilled: function() {
        return this.planCost + this.joinFee - this.joinDiscount - this.discount;
      },
      upgradeForm: function () {
        return this.formType === 'upgrade';
      }
    },
    methods: {
      buildRailsParamsHash: function() {
        return {
          user: {
            account_type:           this.plan.id,
            age_group_id:           this.ageGroup.id,
            birthday:               this.birthday,
            email:                  this.email,
            ethnicity:              this.ethnicity,
            first_name:             this.firstName,
            gender:                 this.gender,
            ideal_weight:           this.idealWeight,
            last_name:              this.lastName,
            password:               this.password,
            password_confirmation:  this.passwordConfirmation,
            physical_activity:      this.physicalActivity,
            timezone:               this.timeZone,
            weight:                 this.weight,
            questionnaire_answer_attributes:  this.buildQuestionnaireAnswerParams()
          }
        }
      },
      buildRailsRegistrationParamsHash: function() {
        return {
          user: {
            birthday:               this.fullBirthday,
            email:                  this.email,
            ethnicity:              this.ethnicity,
            first_name:             this.firstName,
            last_name:              this.lastName,
            password:               this.password,
            password_confirmation:  this.passwordConfirmation,
            timezone:               this.timeZone,
          }
        }
      },
      buildRailsUpdateParamsHash: function() {
        let params = this.buildRailsParamsHash();
        delete params["user"]["password"];
        delete params["user"]["password_confirmation"];
        params.id = this.userId;
        return params;
      },
      buildQuestionnaireAnswerParams: function() {
        let params = {
          body_type:                    this.bodyType,
          face_shape:                   this.faceShape,
          food_cravings:                this.foodCraving ? this.foodCraving.value : null,
          steady_energy_level:          this.energyLevel ? this.energyLevel.code : null,
          morning_person:               this.morningPerson ? this.morningPerson.code : null,
          early_puberty:                this.earlyOnsetPuberty ? this.earlyOnsetPuberty.code : null,
          sluggish_metabolism:          this.sluggishMetabolism ? this.sluggishMetabolism.code : null,
          post_menopausal_body_type_2:  this.postMenopausalCombo ? this.postMenopausalCombo.code : null,
          difficulty_gaining_weight:    this.difficultyGainingWeight ? this.difficultyGainingWeight.code : null,
          energy_fluctuations:          this.energyFluctuations ? this.energyFluctuations.code : null,
          insulin_resistant:            this.insulinResistance ? this.insulinResistance.code : null
        };
        // Male/Female questions:
        if (this.isFemale) {
          this.buildQuestionParams(params, this.femaleQuestion3, this.femaleQuestion3Options);
          this.buildQuestionParams(params, this.femaleQuestion4, this.femaleQuestion4Options);
          this.buildQuestionParams(params, this.femaleQuestion5, this.femaleQuestion5Options);
        }
        if (this.isMale) {
          this.buildQuestionParams(params, this.maleQuestion3, this.maleQuestion3Options);
          this.buildQuestionParams(params, this.maleQuestion4, this.maleQuestion4Options);
        }
        return params;
      },

      buildQuestionParams: function(params, selected, options) {
        options.forEach(option => {
          // skip empty code ('none of the above' answer)
          if (option.code) {
            if (selected) {
              // if an answer is selected, make it true and set others to false
              params[option.code] = (option.code === selected.code);
            } else {
              // if no answer selected for the question, set all values to null
              params[option.code] = null;
            }
          }
        });
      },

      calculateSelectedQuestion: function(options) {
        var answer = this.questionnaire_answer;
        var values = options.map(option => answer[option.code]);
        var null_idx = values.findIndex(v => v === null);
        var selected = options.find(option => answer[option.code]);
        return selected || ((null_idx >= 0) ? null : OPTION_NONE_RELEVANT);
      },

      calculatePortionsProfile: function() {
        return this.bodyType;
      },
      capitalize: function(text) {
        return text.charAt(0).toUpperCase() + text.slice(1)
      },
      createPaymentMethod: function() {
        var self = this;
        this.stripeObject
          .createPaymentMethod({
            type: 'card',
            card: this.credit_card,
            billing_details: {
              name: this.fullName,
            },
          })
          .then((result) => {
            console.log('createPaymentMethod success:', result);
            if (result.error) {
              self.displayCardError(result);
            } else {
              self.createSubscription({
                plan: self.plan.id,
                customerId: self.stripeCustomerId,
                paymentMethodId: result.paymentMethod.id,
                userId: self.userId,
                promoCode: (self.promoCodeValid ? self.promoCode : null)
              });
            }
          }).catch(function (e) {
            console.log('createPaymentMethod failed:', e);
          });
      },
      createStripeCustomer: function() {
        var self = this;
        axios
          .post('/stripe_create_customer', { user_id: this.userId})
          .then(function (response) {
            console.log('response.customer_stripe_id: ' + response.data.customer_stripe_id);
            self.stripeCustomerId = response.data.customer_stripe_id;
            self.createPaymentMethod();
          }, error => {
            console.log(error.response.data.errors);
            console.log("in createStripeCustomer error")
            self.displayErrorMessage(self, error.response.data.errors);
          })
          .catch(function (error) {
            console.log("in createStripeCustomer catch")
            self.displayErrorMessage(self, error.response.data.errors);
          });
      },

      loadStripe() {
        if (!this.stripeLoaded) {
          this.stripeLoaded = true;
          this.includeStripe('js.stripe.com/v3/', this.configureStripe);
        }
      },

      // get Stripe API KEY and setup Stripe instance
      configureStripe(){
        let self = this;
        axios
          .get("/stripe_publishable_key/")
          .then(function (response) {
            self.stripeAPIToken = response.data.stripe_publishable_key;
            self.stripeObject = Stripe( self.stripeAPIToken );
            // TODO refactor and add these in
            // this.elements = this.stripe.elements();
            // this.card = this.elements.create('card');
            // this.card.mount('#card-element');
            self.loadStripeCardElements(self);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      createSinglePayment: function({ paymentMethodId, customerId, userId }) {
        console.log("in create single payment: " + paymentMethodId + " "+ customerId);
        var params = {
          customer_stripeid: customerId,
          payment_method_stripeid: paymentMethodId,
          user_id: userId
        }
        var self = this;
        return (
          axios
            .post('/stripe_create_single_payment', params)
            .then(function (response) {
              console.log("first then - response: " + JSON.stringify(response));
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              console.log("second then - error check...");
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              console.log("third then - " + " " + JSON.stringify(result));
              self.onSinglePaymentComplete(self, result);
            })
            .catch(function (error) {
              console.log("in card error catch");
              self.displayErrorMessage(self, error)
            })
        );
      },
      createSubscription: function({ plan, customerId, paymentMethodId, userId, promoCode }) {
        console.log("in create subscription: " + plan + " " + customerId + " " + paymentMethodId);
        var params = {
          plan:                     plan,
          customer_stripeid:        customerId,
          payment_method_stripeid:  paymentMethodId,
          user_id:                  userId,
          promo_code:               promoCode
        }

        if (this.partnerCode) {
          params.pc = this.partnerCode;
        }

        var self = this;
        return (
          axios
            .post('/stripe_create_subscription', params)
            .then(function (response) {
              return response;
            })
            // If the card is declined, display an error to the user.
            .then(function (result) {
              if (result.errors) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // Normalize the result to contain the object returned by Stripe.
            // Add the additional details we need.
            .then(function (result) {
              return {
                subscription: result.data.subscription,
                paymentMethodId: paymentMethodId
              };
            })
            // If attaching this card to a Customer object succeeds,
            // but attempts to charge the customer fail, you
            // get a requires_payment_method error.
            .then(function (result) {
              return self.handleRequiresPaymentMethod(result.subscription, result.paymentMethodId);
            })
            // No more actions required. Provision your service for the user.
            .then(function (result) {
              self.onSubscriptionComplete(self, result);
            })
            .catch(function (error) {
              var data = error.response.data;
              if (data && data.errors && data.errors.message) {
                self.displayErrorMessage(self, data.errors.message);
              } else {
                self.displayErrorMessage(self, error);
              }
            })
        );
      },
      clearErrors: function() {
        let displayError = document.getElementById('card-element-errors');
        displayError.textContent = '';
        this.errors = {};
        this.errorMessage = '';
        this.hasError = false;
      },
      displayCardError: function (event) {
        // changeLoadingStatePrices(false);
        this.hideSpinnerDisablePaymentForm();
        let displayError = document.getElementById('card-element-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      },
      displayErrorMessage: function(self, message) {
        self.errorMessage = message;
        self.hasError = true;
        self.hideSpinnerDisablePaymentForm();
      },
      displaySpinnerDisablePaymentForm: function() {
        this.credit_card.update({disabled: true});
        this.submitting = true;
        $('.loader').show();
      },
      hideSpinnerDisablePaymentForm: function() {
        this.credit_card.update({disabled: false});
        this.submitting = false;
        $('.loader').hide();
      },
      getAgeGroupLabel: function(ageGroupID) {
        let returnValue = null;
        for (let i = 0; i < this.ageGroupOptions.length; i++) {
          if(this.ageGroupOptions[i].id === ageGroupID) {
            returnValue = this.ageGroupOptions[i].label;
          }
        }
        return returnValue;
      },
      handleRequiresPaymentMethod: function(subscription, paymentMethodId) {
        console.log("Inside handleRequiresPaymentMethod: " + " " + subscription + ", " + paymentMethodId)
        if (subscription.status === 'active' || subscription.status === 'trialing') {
          // subscription is active, no customer actions required.
          console.log('subscription is active!');
          return { subscription, paymentMethodId };
        } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
          // Using localStorage to manage the state of the retry here,
          // feel free to replace with what you prefer.
          // Store the latest invoice ID and status.
          self.latestInvoiceId = subscription.latest_invoice.id;
          self.latestInvoicePaymentIntentStatus = subscription.latest_invoice.payment_intent.status;
          throw { error: { message: 'Your card was declined.' } };
        } else {
          return { subscription, paymentMethodId };
        }
      },
      // include Stripe.js dynamically
      includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },
      initializeFormData: function() {
        if(this.newForm) {
          this.status = STATUS_REGISTERING;
        } else if(this.editForm) {
          this.status = STATUS_EDITING;
        } else if(this.upgradeForm) {
          this.status = STATUS_UPGRADING;
        }
      },
      initializeUserData: function() {
        this.ageGroup =         { id: this.user["age_group_id"], label: this.getAgeGroupLabel(this.user["age_group_id"]) };
        this.birthdayDay =      this.user["birthday"] ? this.user["birthday"].split(' ')[0] : '',
        this.birthdayMonth =    this.user["birthday"] ? this.user["birthday"].split(' ')[1] : '',
        this.ethnicity =        this.user["ethnicity"],
        this.email =            this.user["email"];
        this.firstName =        this.user["first_name"];
        this.gender =           this.user["gender"];
        this.idealWeight =      this.user["ideal_weight"];
        this.lastName =         this.user["last_name"];
        this.physicalActivity = this.user["physical_activity"];
        this.timeZone =         this.user["timezone"];
        this.userId =           this.user["id"];
        this.weight =           this.user["weight"];
        this.bodyType =         this.questionnaire_answer ? this.questionnaire_answer.body_type : '';
        this.faceShape =        this.questionnaire_answer ? this.questionnaire_answer.face_shape : '';

        var foodCraving = this.questionnaire_answer ? this.questionnaire_answer.food_cravings : '';
        this.foodCraving = FOOD_CRAVINGS.find(c => c.id === foodCraving);

        this.energyLevel =             this.formatQuestionnaireAnswerAttr("steady_energy_level");
        this.morningPerson =           this.formatQuestionnaireAnswerAttr("morning_person");
        this.earlyOnsetPuberty =       this.formatQuestionnaireAnswerAttr("early_puberty");
        this.energyFluctuations =      this.formatQuestionnaireAnswerAttr("energy_fluctuations");
        this.sluggishMetabolism =      this.formatQuestionnaireAnswerAttr("sluggish_metabolism");
        this.postMenopausalCombo =     this.formatQuestionnaireAnswerAttr("post_menopausal_body_type_2");
        this.difficultyGainingWeight = this.formatQuestionnaireAnswerAttr("difficulty_gaining_weight");
        this.insulinResistance =       this.formatQuestionnaireAnswerAttr("insulin_resistant");

        if (this.questionnaire_answer) {
          this.maleQuestion3 = this.calculateSelectedQuestion(this.maleQuestion3Options);
          this.maleQuestion4 = this.calculateSelectedQuestion(this.maleQuestion4Options);
          this.femaleQuestion3 = this.calculateSelectedQuestion(this.femaleQuestion3Options);
          this.femaleQuestion4 = this.calculateSelectedQuestion(this.femaleQuestion4Options);
          this.femaleQuestion5 = this.calculateSelectedQuestion(this.femaleQuestion5Options);
        }
      },

      formatCurrency: function(value) {
        var sign = (value < 0) ? '-' : '';
        value = Math.abs(value).toFixed(2);
        if (value === '0.00') {
          sign = '';
        }
        return sign + '$' + value;
      },

      formatBooleanObject: function(value) {
        if (value === null) {
          return null;
        }
        if (value) {
          return { label: 'Yes', code: true };
        }
        return { label: 'No', code: false };
      },
      formatErrorMessage: function(attr, error) {
        attr = attr.replace(/questionnaire_answer./g, '').replace(/_/g, ' ');
        return this.capitalize(attr) + ' ' + error;
      },
      formatQuestionnaireAnswerAttr: function(attribute) {
        return this.questionnaire_answer ? this.formatBooleanObject(this.questionnaire_answer[attribute]) : '';
      },
      loadConfirmationPage: function() {
        window.location = `/stripe_confirmation`;
      },
      loadErrorPage: function() {
        window.location = `/stripe_error`;
      },
      loadHomePage: function() {
        window.location = `/`;
      },
      loadPaymentForm: function() {
        this.status = STATUS_COLLECTING_PAYMENT_INFO
      },
      loadStripeCardElements: function(self) {
        const elements = self.stripeObject.elements();
        const credit_card = elements.create('card');
        credit_card.mount('#card-element');
        credit_card.on('change', function (event) {
          self.displayCardError(event);
          self.creditCardComplete = event.complete && !event.error;
        });
        self.credit_card = credit_card;
        self.stripeLoaded = true;
      },
      loadUserData: function(attribute) {
        if(this.editForm) {
          this.initializeUserData();
        } else if(this.upgradeForm) {
          this.plan = { label: this.capitalize(PLAN_PREMIUM), id: PLAN_PREMIUM };
          this.userId = this.user["id"];
        }
      },
      loadUserShowPage: function(userId) {
        console.log("in user show page")
        window.location = "/users/" + userId;
      },

      onDisclaimerOpen: function(e) {
        $('#disclaimer-modal').modal('show');
      },

      onDisclaimerChange: function(e) {
        this.disclaimer.accepted = e.target.checked;
      },

      onDisclaimerScroll: function(e) {
        var box = e.target;
        if (box.scrollHeight - box.scrollTop - box.clientHeight <= 5) {
          this.disclaimer.read = true;
        }
      },

      // submitting the registration + payment all in one go
      onFullSignupSubmit: async function() {
        this.clearErrors();
        this.submitting = true;

        if (!this.userId) {
          const userParams = this.buildRailsRegistrationParamsHash();
          if (this.partnerCode) {
            userParams.pc = this.partnerCode;
          }

          // validation without creation, not used at the moment
          /*
          const userResponse = await axios.post('/user_validate', userParams);
          const userErrors = userResponse.data.errors;
          const keys = Object.keys(userErrors);
          if (keys.length) {
            this.setUserErrors(userErrors);
            return;
          }
          */
          try {
            const createUserResponse = await axios.post('/users', userParams);
            this.userId = createUserResponse.data.user_id;
            // not signing in for now since email must be confirmed first
            // this.signInUser();
          } catch (error) {
            this.setUserErrors(error.response.data.errors);
            this.submitting = false;
          }
        }
        if (this.userId) {
          this.displaySpinnerDisablePaymentForm();
          this.createStripeCustomer();
        }
      },

      onSubmitPaymentCollectionForm: function() {
        this.displaySpinnerDisablePaymentForm();
        this.createStripeCustomer();
      },
      onSubmitPlanSelectionForm: function() {
        this.loadPaymentForm();
      },
      onSubmitPortionsProfileQuestions: function() {
        let params = this.buildRailsUpdateParamsHash();
        let self = this;

        axios
          .put('/users', params)
          .then((response) => {
            console.log('update succeeded - user_id: ' + response.data.user_id);

            if(self.newForm) {
              self.status = STATUS_PORTIONS_PROFILE_COMPLETE;
              self.loadConfirmationPage();
            } else if (self.editForm) {
              self.loadUserShowPage(response.data.user_id);
            }
          }, error => {
            console.log(error.response.data);
            self.setUserErrors(error.response.data.errors);
          })
          .catch(error => {
            console.log("in catch: " + error);
          });
      },

      onPasswordToggle: function(e) {
        const container = e.currentTarget;
        const icon = container.querySelector('img');
        const input = container.parentNode.querySelector('input');
        if (input.type === 'password') {
          input.type = 'text';
          icon.src = '/images/eye.svg';
        } else {
          input.type = 'password';
          icon.src = '/images/eye_off.svg';
        }
      },

      onSubmitRegistrationForm: function() {
        let params = this.buildRailsRegistrationParamsHash();
        var self = this;

        axios
          .post('/users', params)
          .then((response) => {
            console.log('response.user_id: ' + response.data.user_id);
            self.userId = response.data.user_id;
            // not signing in for now since email must be confirmed first
            // this.signInUser();
            self.status = STATUS_SELECTING_PLAN;
          }, error => {
            console.log(error.response.data);
            self.setUserErrors(error.response.data.errors);
          })
          .catch(error => {
            console.log("in catch: " + error.response.data.errors);
            self.errorMessage = "An error occurred.  Please contact admin.";
          });
      },
      onRegistrationShortcut: function() {
        this.birthdayMonth = "Oct"
        this.birthdayDay = "27"
        this.email = "test@fake.com"
        this.ethnicity = "Asian"
        this.firstName = "Bob"
        this.lastName = "Smith"
        this.password = "password"
        this.passwordConfirmation = "password"
        this.timeZone = "Alaska"
      },
      onSinglePaymentComplete: function(self, result) {
        console.log("reached single payment complete! - " + JSON.stringify(result));
        self.credit_card.destroy();
        self.status = STATUS_PAYMENT_COMPLETE;
      },
      onSubscriptionComplete: function(self, result) {
        console.log("reached subscription complete! - " + result.subscription.status);
        // remove Stripe credit card
        self.credit_card.destroy();
        // Payment was successful.
        if (result.subscription.status === 'active' || result.subscription.status === 'trialing') {
          console.log('subscription is active! You can display last page');

          if(self.upgradeForm) {
            window.location = '/users/' + self.userId;
          } else {
            self.status = STATUS_PAYMENT_COMPLETE;
          }
        } else {
          self.loadErrorPage();
        }
      },
      // TODO Need to hook this up
      setBirthdayDayOptions: function(event) {
        console.log("value: " + event.target.value);
        switch (event.target.value) {
          case "Jan", "Mar", "May", "July", "Aug", "Oct", "Dec":
            if(!this.dayOptions.includes("31")) {
              this.dayOptions = this.originalDayOptions;
            }
          case "Apr", "June", "Sept", "Nov":
            this.dayOptions = this.originalDayOptions;
            this.dayOptions.splice(30, 1)
          case "Feb":
            this.dayOptions = this.originalDayOptions;
            this.dayOptions.splice(27, 4);
        }
      },
      signInData: function() {
        return {
          user: {
            remember_me: 1,
            password: this.password,
            email: this.email
          }
        };
      },
      signInUser: function() {
        let self = this;
        this.$http.post('/users/sign_in.json', this.signInData()).then( response => {
          console.log('Signed in successfully');
          this.userSignedIn = true;
        }, response => {
          console.log('Unable to signin' + JSON.stringify(response));
        })
      },
      signOutUser: function() {
        console.log('in signout user');
        let self = this;
        axios
          .delete("/users/sign_out")
          .then((response) => {
            console.log('Signed out successfully');
          }, error => {
            console.log('Unable to signout' + JSON.stringify(error));
          })
      },
      setUserErrors: function(errors) {
        // filter only errors which have actual messages
        var present = {};
        for (var k in errors) {
          if (errors[k].length > 0) {
            present[k] = errors[k];
          }
        }
        this.errors = present;
        this.$scrollTo(this.errors);
      },
      onPromoCodeChange: function() {
        if (this.promoCode === '') {
          this.discount = 0;
          this.joinDiscount = 0;
        }
        this.promoCodeValid = undefined;
      },
      validatePromoCode: function() {
        var self = this;
        var url = "/billing_coupons/" + this.promoCode;
        if (this.partnerCode) {
          url += '?pc=' + this.partnerCode;
        }
        axios
          .get(url)
          .then(function (response) {
            var coupon = response.data;
            if (coupon) {
              self.promoCodeValid = coupon;
              var monthlyPercentOff = parseFloat(coupon.percent_off || 0);
              if (monthlyPercentOff > 0) {
                self.discount = 0.01 * coupon.percent_off * self.planCost;
              } else if (coupon.amount_off) {
                self.discount = 0.01 * coupon.amount_off;
              }
              var joinPercentOff = parseFloat(coupon.join_percent_off || 0);
              if (joinPercentOff > 0) {
                self.joinDiscount = 0.01 * coupon.join_percent_off * self.joinFee;
              } else if (coupon.join_amount_off) {
                self.joinDiscount = 0.01 * coupon.join_amount_off;
              }
            } else {
              self.promoCodeValid = false;
              self.discount = 0;
              self.joinDiscount = 0;
            }
          }).catch(function(e) {
            self.promoCodeValid = false;
            self.discount = 0;
            self.joinDiscount = 0;
          });
      }
    }
  });

  var element = document.getElementById("questionnaire-app");

  if (element != null) {
    const QuestionnaireApp = new Vue({
      el: element,
      template: '#questionnaire-app-template',
      data: () => {
        return {
          message: "Vue is working"
        }
      }
    })
  }

  var element = document.getElementById("questionnaire-app-edit");

  if (element != null) {
    const QuestionnaireApp = new Vue({
      el: element,
      template: '#questionnaire-app-template-edit'
    })
  }
})
