import Vue from 'vue/dist/vue.esm';

import axios from 'axios';
import VueAutosuggest from "vue-autosuggest";
import vSelect from 'vue-select';
import {EventBus} from "./event-bus";

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(VueAutosuggest);
Vue.component('v-select', vSelect)

document.addEventListener('turbolinks:load', () => {

  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  const ASSOCIATION_HISTORY = "history";
  const ASSOCIATION_RECIPE = "recipe"

  const AddFoodForm = Vue.component('add-food-form', {
    template: '#add-food-form-template',
    props: [
      'histories', 'association', 'ingredients', 'recipe', 'recipebank', 'readonly', 'eatenDaysAgo', 'canAddNewFoods'
    ],
    mounted: function() {
      console.log('mounted, this.eatenDaysAgo:', this.eatenDaysAgo);
      EventBus.$on('newFoodAdded', this.addFood)
      EventBus.$on('foodUpdated', this.onFoodUpdated)
      this.loadedFood = this.recipe;
      if (this.loadedFood) {
        this.selectedMetabolicTypes = this.loadedFood.metabolic_type_ids;
      }
      this.loadFood();
    },
    data: function() {
      return {
        defaultRecipeCategories: [
          "breakfast",
          "lunch_dinner_entrees",
          "sides",
          "snacks",
          "dressings",
          "drinks",
          "vegan",
          "vegetarian",
        ],
        historyAdded: [],
        ingredientsAdded: [],
        loadedFood: '',
        selectedMetabolicTypes: [],
        recipeCategories: [],
        recipeId: '',
        recipeName: '',
        recipeServingSize: '',
        recipeServings: '',
        recipeImage: '',
        recipeImageUrl: '',
        recipeUpdateNotification: ''
      }
    },
    computed: {
      displayAddedFoodHeaders: function() {
        return (this.foodAdded.length > 0);
      },
      displayEditingButtons: function() {
        return !this.hideEditingButtons;
      },
      displayFoodSelectionButtons: function() {
        return this.canAddNewFoods;
      },
      displayMyRecipesButton: function() {
        return this.association === ASSOCIATION_HISTORY;
      },
      displayRecipeButtons: function() {
        return this.association === ASSOCIATION_RECIPE;
      },
      foodAdded: function() {
        if(this.association === ASSOCIATION_HISTORY) {
          return this.historyAdded;
        } else {
          return this.ingredientsAdded;
        }
      },
      hideEditingButtons: function() {
        var eatenToday = this.eatenDaysAgo == 0;
        var eatenYesterday = this.eatenDaysAgo == 1;
        return this.readonly && (!eatenToday) && (!eatenYesterday);
      },
      isCreatingRecipe: function() {
        if(this.recipeId) {
          return false;
        } else {
          return true;
        }
      },
      hasRecipeImageUploaded: function() {
        if(this.recipeImageUrl) {
          return true;
        } else {
          return false;
        }
      },
      isRecipeBank: function() {
        if(this.recipebank) {
          return true
        } else {
          return false;
        }
      },
      isRecipeSaved: function() {
        return this.recipeId;
      },
      isRecipeValid: function() {
        return this.ingredientsAdded.length > 0 &&
          this.recipeName &&
          this.recipeServings;
      },
      noRecipeImageUploaded: function() {
        return !this.hasRecipeImageUploaded;
      },
      recipeCanBeScaledToOne: function() {
        if(this.recipeServings === 1) {
          return false;
        } else {
          return true;
        }
      },
      recipeSubmitText: function() {
        if(this.isRecipeBank) {
          return "Save to Recipe Bank"
        } else {
          if(this.isCreatingRecipe) {
            return "Save My Recipe"
          } else {
            return "Update Recipe"
          }
        }
      }
    },
    methods: {
      addFood: function(response) {
        console.log("in add food! - new food added:::: " + JSON.stringify(response));

        if(this.association === ASSOCIATION_HISTORY) {
          this.historyAdded.push(response);
        }
        else {
          this.ingredientsAdded.push(response);
        }
      },
      buildCreateRecipeParams: function() {
        var ingredient_ids = []
        for (let i = 0; i < this.ingredientsAdded.length; i++) {
          ingredient_ids.push(this.ingredientsAdded[i].id)
        }

        let params = {
          name: this.recipeName,
          serving_size: this.recipeServingSize,
          servings: this.recipeServings,
          ingredient_ids: JSON.stringify(ingredient_ids),
          recipe_image: this.recipeImage,
          metabolic_type_ids: []
        }

        // add recipe bank params
        if(this.isRecipeBank) {
          params["is_recipe_bank"] = true;
          for (let i = 0; i < this.recipeCategories.length; i++) {
            params[this.recipeCategories[i]] = true;
          }
          for (let i = 0; i < this.selectedMetabolicTypes.length; i++) {
            params.metabolic_type_ids.push(this.selectedMetabolicTypes[i]);
          }
        }
        return params;
      },
      buildUpdateRecipeParams: function() {
        let params = {
          name: this.recipeName,
          serving_size: this.recipeServingSize,
          servings: this.recipeServings,
          recipe_image: this.recipeImage,
          metabolic_type_ids: []
        }

        // add recipe bank params
        if(this.isRecipeBank) {
          params["is_recipe_bank"] = true;
          for (let i = 0; i < this.recipeCategories.length; i++) {
            params[this.recipeCategories[i]] = true;
          }
          for (let i = 0; i < this.selectedMetabolicTypes.length; i++) {
            params.metabolic_type_ids.push(this.selectedMetabolicTypes[i]);
          }
        }
        return params;
      },
      createRecipe: function() {
        let params = this.buildCreateRecipeParams();
        let self = this;
        let formData = new FormData();

        Object.entries(params).forEach(
          ([key, value]) => formData.append(key, value)
        )

        axios
          .post('/recipes', formData)
          .then((response) => {
            console.log('Recipe created! - response: ' + JSON.stringify(response.data));
            self.recipeId = response.data.recipe.id;
            self.loadedFood = response.data.recipe;
            self.loadFood();
            this.recipeUpdateNotification = "Recipe Created!"
          }, error => {
            console.log(error.response.data.errors);
            alert('Unable to save recipe')
          })
          .catch(error => {
            console.log("in createHistory catch: " + error);
          });
      },
      deleteFood: function(index) {
        console.log("in delete food axios call: " + index);

        if(this.association === ASSOCIATION_HISTORY) {
          this.deleteHistory(index);
        }
        else {
          this.deleteIngredient(index);
        }
      },
      deleteHistory: function(index) {
        console.log("in delete history axios call: " + index);
        var historyId = this.historyAdded[index].id

        axios
          .delete("/history", { data: { id: historyId }})
          .then((response) => {
            console.log('Food History deleted! - id: ' + response.data.id);
            this.$delete(this.historyAdded, index);
          }, error => {
            alert("Could not delete this food entry.");
          })
          .catch(error => {
            console.log("in deleteHistory catch: " + error);
          });
      },
      deleteIngredient: function(index) {
        console.log("in delete ingredient axios call: " + index);
        var ingredientId = this.ingredientsAdded[index].id

        axios
          .delete("/ingredients/" + ingredientId)
          .then((response) => {
            console.log('Food Ingredient deleted! - id: ' + response.data.id);
            this.$delete(this.ingredientsAdded, index);
          }, error => {
            alert("Could not delete this food entry.");
          })
          .catch(error => {
            console.log("in deleteIngredient catch: " + error);
          });
      },
      editFood: function(index) {
        console.log("in edit food: " + index);
        var payload = {};
        if(this.association === ASSOCIATION_HISTORY) {
          payload = this.historyAdded[index];
        } else if(this.association === ASSOCIATION_RECIPE) {
          payload = this.ingredientsAdded[index];
        }
        EventBus.$emit('editFoodClicked', payload);
      },
      getPortionsPreview: function() {
        var self = this;
        axios
          .get("/recipes/" + this.recipeId + "/preview")
          .then(function (response) {
            $('#preview-recipe .modal-body').html(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      loadFood: function() {
        if(this.histories) {
          this.historyAdded = this.histories;
        } else if(this.loadedFood) {
          this.recipeId = this.loadedFood.id
          this.recipeName = this.loadedFood.name;
          this.recipeServingSize = this.loadedFood.serving_size;
          this.recipeServings = this.loadedFood.servings;
          this.ingredientsAdded = this.loadedFood.ingredients;
          this.recipeImageUrl = this.loadedFood.recipe_image_url;
          if(this.isRecipeBank) {
            this.loadSelectedRecipeCategories();
            this.selectedMetabolicTypes = this.loadedFood.metabolic_type_ids;
          }
        }
      },
      loadSelectedRecipeCategories: function() {
        if(this.loadedFood.breakfast && !this.recipeCategories.includes("breakfast")) {
          this.recipeCategories.push("breakfast");
        }
        if(this.loadedFood.lunch_dinner_entrees && !this.recipeCategories.includes("lunch_dinner_entrees")) {
          this.recipeCategories.push("lunch_dinner_entrees");
        }
        if(this.loadedFood.sides && !this.recipeCategories.includes("sides")) {
          this.recipeCategories.push("sides");
        }
        if(this.loadedFood.snacks && !this.recipeCategories.includes("snacks")) {
          this.recipeCategories.push("snacks");
        }
        if(this.loadedFood.dressings && !this.recipeCategories.includes("dressings")) {
          this.recipeCategories.push("dressings");
        }
        if(this.loadedFood.drinks && !this.recipeCategories.includes("drinks")) {
          this.recipeCategories.push("drinks");
        }
        if(this.loadedFood.vegan && !this.recipeCategories.includes("vegan")) {
          this.recipeCategories.push("vegan");
        }
        if(this.loadedFood.vegetarian && !this.recipeCategories.includes("vegetarian")) {
          this.recipeCategories.push("vegetarian");
        }
      },
      onClickFavorites: function() {
        $('#myModal').modal('show');
        var payload = {};
        if(this.association === ASSOCIATION_RECIPE) {
          payload = { recipe_id: this.recipeId };
        }
        EventBus.$emit('favoritesClicked', payload);
      },
      onClickFoodSearch: function() {
        $('#myModal').modal('show');
        var payload = {};
        if(this.association === ASSOCIATION_RECIPE) {
          payload = { recipe_id: this.recipeId };
        }
        EventBus.$emit('foodSearchClicked', payload);
      },
      onClickPreviewPortion: function() {
        $('#preview-recipe').modal('show');
        this.getPortionsPreview();
      },
      onClickRecipes: function() {
        $('#myModal').modal('show');
        EventBus.$emit('recipesClicked');
      },
      onClickScaleRecipeToOneServing: function() {
        let url = "/recipes/" + this.recipeId + "/scale_to_single/";

        axios
          .post(url)
          .then((response) => {
            this.loadedFood = response.data.recipe;
            this.selectedMetabolicTypes = this.loadedFood.metabolic_type_ids;
            this.loadFood();
          }, error => {
            alert('An error occurred.  Unable to scale recipe.')
          })
          .catch(error => {
            console.log("in scale recipe catch: " + error);
          });
      },
      onFoodUpdated: function(response) {
        this.updateFood(response);
      },
      onRecipeUploadImageSelected: function(event) {
        this.recipeImage = this.$refs.inputFile.files[0];
      },
      onSaveRecipeClick: function() {
        if(this.isCreatingRecipe) {
          this.createRecipe();
        } else {
          this.updateRecipe();
        }
      },
      printRecipe: function() {
        const modal = document.getElementById("recipe-image")
        const cloned = modal.cloneNode(true)
        let section = document.getElementById("print")

        if (!section) {
          section  = document.createElement("div")
          section.id = "print"
          document.body.appendChild(section)
        }

        section.innerHTML = "";
        section.appendChild(cloned);
        window.print();
      },
      updateFood: function(response) {
        if(this.association === ASSOCIATION_HISTORY) {
          for (let i = 0; i < this.historyAdded.length; i++) {
            if(this.historyAdded[i].id === response.id) {
              this.$set(this.historyAdded, i, response);
            }
          }
        } else if(this.association === ASSOCIATION_RECIPE) {
          for (let i = 0; i < this.ingredientsAdded.length; i++) {
            if(this.ingredientsAdded[i].id === response.id) {
              this.$set(this.ingredientsAdded, i, response);
            }
          }
        }
      },
      updateRecipe: function() {
        let params = this.buildUpdateRecipeParams();
        let formData = new FormData()

        Object.entries(params).forEach(
          ([key, value]) => formData.append(key, value)
        )

        let url = "/recipes/" + this.recipeId;
        let self = this;

        axios
          .put(url, formData)
          .then((response) => {
            this.loadedFood = response.data.recipe;
            this.loadFood();
            this.recipeUpdateNotification = "Recipe Updated!"
          }, error => {
            alert('Unable to update recipe')
          })
          .catch(error => {
            console.log("in updateRecipe catch: " + error);
          });
      }
    }
  });

  var element = document.getElementById("add-food-app");

  if (element != null) {
    const AddFoodApp = new Vue({
      el: element,
      data: () => {
        return {
          message: "Vue is working"
        }
      }
    })
  }
})
